import React, { useRef } from "react";
import Image from "next/image";
import useIsInViewport from "hook/useIsInViewport";
import Breadcrumb from "components/Breadcrumb";

export default function PageHeader({ crumbs, title, subtitle, image = null }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    <section ref={ref} className="relative py-36 pb-40 overflow-hidden">
      <div className="absolute inset-0 bg-primary">
        {image?.image_style_uri ? (
          <>
            <Image
              src={image.image_style_uri.banner_hq}
              alt={image.resourceIdObjMeta.alt}
              layout="fill"
              objectFit="cover"
              objectPosition="right"
              className="transition-all !hidden lg:!block"
              quality={100}
              style={{
                opacity: isInViewport ? 100 : 0,
                transitionDuration: `${transitionDuration * 2}s`,
                transitionDelay: `${transitionDelay}s`,
              }}
            />
            <Image
              src={image.image_style_uri.hq_mobile}
              alt={image.resourceIdObjMeta.alt}
              layout="fill"
              objectFit="cover"
              objectPosition="right"
              className="transition-all lg:!hidden"
              quality={100}
              style={{
                opacity: isInViewport ? 100 : 0,
                transitionDuration: `${transitionDuration * 2}s`,
                transitionDelay: `${transitionDelay}s`,
              }}
            />
          </>
        ) : (
          <>
            <Image
              src="/images/header.jpg"
              layout="fill"
              objectFit="cover"
              objectPosition="right"
              alt="Bannière d'en-tête"
              className="transition-all !hidden lg:!block"
              quality={100}
              style={{
                opacity: isInViewport ? 100 : 0,
                transitionDuration: `${transitionDuration * 2}s`,
                transitionDelay: `${transitionDelay}s`,
              }}
            />
            <Image
              src="/images/header-mobile.jpg"
              layout="fill"
              objectFit="cover"
              objectPosition="right"
              alt="Bannière d'en-tête"
              className="transition-all lg:!hidden"
              quality={100}
              style={{
                opacity: isInViewport ? 100 : 0,
                transitionDuration: `${transitionDuration * 2}s`,
                transitionDelay: `${transitionDelay}s`,
              }}
            />
          </>
        )}
        <div className="absolute inset-0 bg-default/40" aria-hidden="true" />
        <div
          className="absolute inset-0 bg-gradient-to-r from-primary to-transparent"
          aria-hidden="true"
        />
      </div>
      <div className="relative container mx-auto px-4 text-white">
        <Breadcrumb
          crumbs={crumbs}
          className="mb-4 transition-all"
          isInViewport={isInViewport}
        />

        <h1
          className="flex-1 text-4xl lg:text-7xl font-important transition-all"
          style={{
            opacity: isInViewport ? 100 : 0,
            transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
            transitionDuration: `${transitionDuration}s`,
            transitionDelay: `${transitionDelay}s`,
          }}
        >
          {title}
        </h1>

        {subtitle && (
          <div className="flex justify-center lg:justify-start items-center mt-16">
            {typeof subtitle === "string" ? (
              <>
                <span
                  className="inline-block h-2.5 w-2.5 mr-4 border-2 border-primary rounded-full"
                  aria-hidden="true"
                />
                <p>{subtitle}</p>
              </>
            ) : (
              subtitle
            )}
          </div>
        )}
      </div>
    </section>
  );
}
