import React from "react";
import useTranslation from "next-translate/useTranslation";
import { ChevronRightIcon } from "@heroicons/react/outline";
import NextLink from "components/NextLink";

export default function Breadcrumb({ crumbs, className, isInViewport = true }) {
  const { t } = useTranslation("common");
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  const withHome = [
    {
      path: "home.path",
      title: "home.title",
      translate: true,
    },
    ...crumbs,
  ];

  return (
    <nav aria-label={t("breadcrumb.title")} className={className}>
      <ol className="flex flex-wrap items-center">
        {withHome.map((crumb, index) => {
          const isFirst = index === 0;
          const thisTransitionDelay = transitionDelay * index;

          return (
            <li
              key={`crumb-${crumb.title}`}
              className="font-important font-medium uppercase text-sm transition-all"
              style={{
                opacity: isInViewport ? 100 : 0,
                transform: `translateX(${isInViewport ? 0 : "-1rem"})`,
                transitionDuration: `${transitionDuration}s`,
                transitionDelay: `${thisTransitionDelay}s`,
              }}
            >
              {!isFirst && (
                <span
                  className="inline-block px-2 separator"
                  aria-hidden="true"
                >
                  <ChevronRightIcon className="h-3 w-3" />
                </span>
              )}
              {crumb.path ? (
                <NextLink
                  href={crumb.translate ? t(crumb.path) : crumb.path}
                  className="underline underline-offset-4 decoration-transparent hover:decoration-white transition-colors"
                  passHref
                >
                  {crumb.translate ? t(crumb.title) : crumb.title}
                </NextLink>
              ) : (
                <span className="text-opacity-80">
                  {crumb.translate ? t(crumb.title) : crumb.title}
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
