
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import Layout from "components/Layout";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import PageHeader from "components/PageHeader";
import Link from "next/link";
import { EmojiSadIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";
import { LISTS, getMeta } from "lib/getMeta";

export default function NotFoundPage({ globals, meta }) {
  const { t } = useTranslation("common");

  return (
    <Layout globals={globals}>
      <Meta
        title={meta?.title || t("not_found.title")}
        description={meta?.description || t("not_found.description")}
        useTemplate
      />

      <PageHeader crumbs={[]} title="Erreur 404" />

      <section className="py-16">
        <div className="relative container mx-auto px-4 lg:max-w-screen-lg">
          <div className="prose text-center">
            <EmojiSadIcon className="h-8 w-8 inline-block" />
            <p>{t("not_found.description")}</p>
            <Link href="/" passHref>
              <a className="button button--primary">{t("not_found.link")}</a>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
}

 async function _getStaticProps(context) {
  return {
    props: {
      globals: await getGlobals(context),
      meta: await getMeta(LISTS[404], context),
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  